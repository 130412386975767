<template>
  <div>
    <van-nav-bar title="任务查询" left-text="返回" left-arrow @click-left="back()" />
  </div>
  <van-field
      v-model="search"
      is-link
      readonly
      name="picker"
      label="搜索条件"
      placeholder="选择搜索条件"
      @click="searchPicker = true"
  />
  <van-popup v-model:show="searchPicker" position="bottom">
    <van-picker
        :columns="columns"
        @confirm="onConfirm"
        @cancel="searchPicker = false"
    />
  </van-popup>
  <van-search v-model="value" placeholder="请输入搜索关键词" />
  <div class="d_form">
    <div class="all">
      <div class="table-container">
        <table class="wide-table2">
          <tr>
            <th>序号</th>
            <th>任务名称</th>
            <th>工装编码</th>
            <th>任务模板</th>
            <th>开始时间</th>
            <th>结束时间</th>
            <th>起始库区</th>
            <th>目标库区</th>
            <th>状态</th>
          </tr>
          <tr v-for="user in list" :key="user.id">
            <td>{{ user.num }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.jig_code }}</td>
            <td>{{ user.start }}</td>
            <td>{{ user.end }}</td>
            <td>{{ user.mod }}</td>
            <td>{{ user.start }}</td>
            <td>{{ user.end }}</td>
            <td>{{ user.status }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router"
import { Toast } from 'vant';
import { ref } from 'vue'
const router = useRouter()
const search = ref(null);
const searchPicker = ref(null)
const columns = [
  { text: '起始仓库', value: 0 },
  { text: '目标仓库', value: 1 },
  { text: '开始时间', value: 2 },
  { text: '结束时间', value: 3 },
  { text: '状态', value: 4 },
];
const list = ref([
  {
    num: 1,
    name: 'task_1',
    jig_code: 'jig-05',
    mod: 'f01',
    status: '执行中',
    start: '库区A',
    end: '库区B'
  },
  {
    num: 2,
    name: 'task_1',
    jig_code: 'jig-05',
    mod: 'f01',
    status: '执行中',
    start: '库区A',
    end: '库区B'
  },
  {
    num: 3,
    name: 'task_1',
    jig_code: 'jig-05',
    mod: 'f01',
    status: '执行中',
    start: '库区A',
    end: '库区B'
  },
  {
    num: 4,
    name: 'task_1',
    jig_code: 'jig-05',
    mod: 'f01',
    status: '执行中',
    start: '库区A',
    end: '库区B'
  },
  {
    num: 5,
    name: 'task_1',
    jig_code: 'jig-05',
    mod: 'f01',
    status: '执行中',
    start: '库区A',
    end: '库区B'
  }
]);
const loading = ref(false);
const finished = ref(false);
const onLoad = () => {
};
function back() {
  router.push({ path: "/index" })
}

</script>
<style scoped>
.flex{
  display: flex;
}
.scan{
  align-items: center;
}
.kan{
  justify-content: space-between;
  align-items: center;
}
</style>
<style>
.list2 .van-cell__title span{
  font-size: 0.3rem;
}
.fz {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}
.fz div{
  width: 100px;
  display: flex;
  font-size: 0.3rem;
  margin-right: 10px;
}
.all{
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}
.flex{
  display: flex;
  width: 100%;
  overflow: scroll;
  justify-content: space-between;
}
</style>
